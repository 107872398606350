//import { useCreateBlockNote } from "@blocknote/react"
import { useEffect, useRef } from "react"
import { CustomCheckoutBlock } from "../../core/types/CustomCheckout"
import CustomCheckoutFunctions from "../../core/functions/CustomCheckoutFunctions"
//import { BlockNoteView } from "@blocknote/mantine"
//import "./blocknote.css"
//import "@blocknote/core/fonts/inter.css"
//import "@blocknote/mantine/style.css"

export interface ICheckoutTextEditorProps {
  initialContent: string
}

function CheckoutTextView({ initialContent }: ICheckoutTextEditorProps) {
  var ref = useRef<HTMLDivElement>(null)

  const getInitialContent = (): CustomCheckoutBlock[] => {
    try {
      return JSON.parse(initialContent) as CustomCheckoutBlock[]
    } catch (e) {
      return []
    }
  }

  //const view = useCreateBlockNote({ initialContent: getInitialContent() })

  useEffect(() => {
    /*if (view) {
      const blocks: Block[] = getInitialContent()
      const topLevelBlocks = view.topLevelBlocks
      const lastBlock = topLevelBlocks.length
      if (lastBlock) {
        view.insertBlocks(blocks, { id: topLevelBlocks[lastBlock - 1].id })
        view.removeBlocks(topLevelBlocks)
      }
    }*/
    if (ref.current) {
      const content = getInitialContent()
      const html = CustomCheckoutFunctions.jsonToHtml(content)
      ref.current.innerHTML = html
    }
  }, [initialContent])

  //return <BlockNoteView editor={view} theme="light" editable={false} />
  return <div ref={ref} style={{ padding: "0px 16px" }}></div>
}

export default CheckoutTextView
