import { IProductPricePaymentInstallment } from "./Product"
import { TSaleAddress } from "./Sale"
import { TProductVoucherValue } from "./Voucher"

export type IOrder = {
  checkoutOrderId?: string
  productPriceId: string
  name: string
  document: string
  email: string
  phone: string
  lostSaleCode?: string
  address: TSaleAddress
  paymentData: PaymentData
  orderBumps?: TOrderBump[]
  affiliateCode?: string | null

  src?: string | null
  sck?: string | null
  utm_campaign?: string | null
  utm_source?: string | null
  utm_medium?: string | null
  utm_content?: string | null
  utm_term?: string | null

  checkoutModelId?: string
  kondutoId?: string
  onlyProducer: boolean

  pageAccessId?: string | null
  sessionToken?: string | null
  clientIP?: string | null

  recaptchaToken: string | null
}

export type IOrderAddress = {
  city: string
  state: string
  country: string
  street: string
  number: string
  neighborhood: string
  zipCode: string
  complement: string
  reference: string
}

export type TOrderBump = {
  productPriceId: string
  description: string
  amount: number
  value: number
}

export type PaymentData = {
  paymentMethod: PaymentMethodEnum
  creditCardData?: ICreditCard
  debitCardData?: IDebitCard
  pixData?: IPix
  boletoData?: IBoleto

  discount: number
  subtotal: number
  total: number

  voucherId?: string | null
  voucherIdentifier?: string | null
  voucherType?: TProductVoucherValue | null
  voucherValue?: number | null
  voucherStatus: "none" | "valid" | "invalid"
}

export type ICreditCard = {
  name: string
  number: string
  installments: number
  month: number
  year: number
  cvv: string
}

export type IDebitCard = {
  name: string
  number: string
  month: number
  year: number
  cvv: string
}

export type IPix = {
  // Data de expiração do Pix em segundos, Ex: 52134613.  Obrigatório se não preencher ExpiresAt
  expiresIn: number

  // 	Data de expiração do Pix. Obrigatório se não preencher ExpiresIn
  expiresAt: Date
}

export type IBoleto = {
  // Instruções do boleto. Max: 256 caracteres.
  instructions: string

  // Identificador do boleto. Max: 16 caracteres.
  documentNumber: string

  // Tipo de espécie do boleto.DM (Duplicata Mercantil) e BDP (Boleto de proposta)
  type: string

  // Código do banco. Ex.: 001 (Banco do Brasil); 033 (Santander); 104 (Caixa Econômica Federal);
  // 197 (Banco Stone); 198 (Pagar.me); 237 (Bradesco); 341 (Itau); 376 (JPMorgan); 745 (Citibank) .
  bank?: string

  // Data de vencimento. (Opcional)
  dueAt?: Date
}

export type IOrderResponse = {
  success: boolean
  checkoutOrderId?: string
  orderCode?: string
  orderId?: string
  chargeId?: string
  paymentType: number
  status?: string
  message?: string
  urlRedirect?: string
}

export enum PaymentMethodEnum {
  CreditCard = 1,
  DebitCard = 2,
  Pix = 3,
  Boleto = 4,
}

export enum IOrderChargeStatus {
  Pending = "pending",
  Paid = "paid",
  Canceled = "canceled",
  Processing = "processing",
  Failed = "failed",
  Overpaid = "overpaid",
  Underpaid = "underpaid",
  Chargedback = "chargedback",
  AntifraudReproved = "antifraud_reproved",
  PaymentFailed = "payment_failed",
  Refunded = "refunded",
}

export type IOrderCharge = {
  id: string
  chargeId: string
  code: string
  status: IOrderChargeStatus
  paymentMethod: string
  transactionId: string
  orderId: string

  pixQrCode?: string
  pixQrUrl?: string

  boletoUrl?: string
  boletoCodeBar?: string
  boletoCodeBarUrl?: string
  boletoPDF?: string
  boletoQrCode?: string
}

export type ICalculateInstallment = {
  installments: Array<IProductPricePaymentInstallment>
}
