import { CustomCheckoutBlock } from "../types/CustomCheckout"

const jsonToHtml = (blocks: CustomCheckoutBlock[]): string => {
  return blocks
    .map((block) => {
      const { type, props, content } = block
      //const contentText = content.map((item) => item.text).join("")

      let htmlContent = ""
      if (content && content.length > 0) {
        htmlContent = content
          .map((item: any) => {
            const styles = `
                color: ${
                  item.styles?.textColor ? item.styles?.textColor : "inherit"
                };
                background-color: ${
                  item.styles?.backgroundColor
                    ? item.styles?.backgroundColor
                    : "inherit"
                };
                text-align: ${item.styles?.textAlignment || "left"};
              `
            return `<span style="${styles}">${item.text}</span>`
          })
          .join("")
      }

      // Define estilos inline
      const styles = `
          color: ${props.textColor !== "default" ? props.textColor : "inherit"};
          background-color: ${
            props.backgroundColor !== "default"
              ? props.backgroundColor
              : "inherit"
          };
          text-align: ${props.textAlignment || "left"};
        `

      if (type === "heading") {
        const level = props.level || 1 // Padrão: nível 1
        return `<h${level} style="${styles}">${htmlContent}</h${level}>`
      }

      if (type === "paragraph") {
        return `<p style="${styles}">${htmlContent}</p>`
      }

      // Outros tipos podem ser tratados aqui
      return `<div style="${styles}">${htmlContent}</div>`
    })
    .join("") // Junta todos os blocos em uma string única
}

const CustomCheckoutFunctions = {
  jsonToHtml,
}

export default CustomCheckoutFunctions
