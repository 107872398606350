import {
  errorCheckoutPaymentCreditCard,
  errorCheckoutPaymentDefault,
  errorCheckoutPaymentPIX,
  methodTypeBoleto,
  methodTypeCreditCard,
  methodTypeDebitCard,
  methodTypePix,
} from "../constants"
import { IResponse } from "../types/ErrorResponse"
import { PaymentMethodEnum } from "../types/Order"
import { TProductFrequency } from "../types/Product"
import { sha256 } from "js-sha256"

const encryptSha256 = (value?: string) => {
  if (!value) {
    return ""
  }
  return sha256(value.toLocaleLowerCase())
}

const paymentMethodToString = (value?: string): string => {
  switch (value) {
    case "credit_card":
      return "Crédito"
    case "debit_card":
      return "Débito"
    case "boleto":
      return "Boleto"
    case "pix":
      return "PIX"
    default:
      return value ?? ""
  }
}

const checkoutOrderStatusToString = (value?: string): string => {
  switch (value) {
    case "pending":
      return "Pendente"
    case "paid":
      return "Pago"
    case "canceled":
      return "Cancelado"
    case "processing":
      return "Processando"
    case "failed":
      return "Falha"
    case "overpaid":
      return "Pago a mais"
    case "underpaid":
      return "Pago a menos"
    case "chargedback":
      return "Estorno"
    default:
      return value ?? ""
  }
}

const methodTypeToString = (value?: number) => {
  switch (value) {
    case methodTypeCreditCard:
      return "Crédito"
    case methodTypeDebitCard:
      return "Débito"
    case methodTypePix:
      return "PIX"
    case methodTypeBoleto:
      return "Boleto"
    default:
      return ""
  }
}

const methodTypeToPaymentMethod = (value?: number) => {
  switch (value) {
    case methodTypeCreditCard:
      return "credit_card"
    case methodTypeDebitCard:
      return "debit_card"
    case methodTypePix:
      return "pix"
    case methodTypeBoleto:
      return "boleto"
    default:
      return ""
  }
}

const productFrequencyToString = (value?: TProductFrequency) => {
  switch (value) {
    case TProductFrequency.Weekly:
      return "Semanal"
    case TProductFrequency.Monthly:
      return "Mensal"
    case TProductFrequency.Bimonthly:
      return "Bimestral"
    case TProductFrequency.Quarterly:
      return "Trimestral"
    case TProductFrequency.Semester:
      return "Semestral"
    case TProductFrequency.Yearly:
      return "Anual"
    default:
      return ""
  }
}

const productFrequencyToType = (value?: TProductFrequency) => {
  switch (value) {
    case TProductFrequency.Weekly:
      return "Semana"
    case TProductFrequency.Monthly:
      return "Mês"
    case TProductFrequency.Bimonthly:
      return "Bimestre"
    case TProductFrequency.Quarterly:
      return "Trimestre"
    case TProductFrequency.Semester:
      return "Semestre"
    case TProductFrequency.Yearly:
      return "Ano"
    default:
      return ""
  }
}

const getErrorPayment = (
  value: PaymentMethodEnum | undefined,
  response?: IResponse
) => {
  if (response && response.error) {
    if (response.error.includes("ReCAPTCHA")) {
      return "Não conseguimos verificar sua identidade. Por favor, tente novamente ou entre em contato com o suporte para assistência."
    }
  }

  switch (value) {
    case PaymentMethodEnum.CreditCard:
      return errorCheckoutPaymentCreditCard
    case PaymentMethodEnum.DebitCard:
      return errorCheckoutPaymentCreditCard
    case PaymentMethodEnum.Pix:
      return errorCheckoutPaymentPIX
    case PaymentMethodEnum.Boleto:
      return errorCheckoutPaymentPIX
    default:
      return errorCheckoutPaymentDefault
  }
}

const replaceSpacesAndNewlines = (input: string | null | undefined): string => {
  let result = ""
  if (input) {
    for (const char of input) {
      if (char === " " || char === "\n") {
        result += "-"
      } else {
        result += char
      }
    }
  }
  return result
}

const Convertions = {
  encryptSha256,
  paymentMethodToString,
  checkoutOrderStatusToString,
  methodTypeToString,
  methodTypeToPaymentMethod,
  productFrequencyToString,
  productFrequencyToType,
  getErrorPayment,
  replaceSpacesAndNewlines,
}

export default Convertions
