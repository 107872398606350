import { Button } from "@mui/material"
import NegativeButton from "../../Buttons/NegativeButton"
import Convertions from "../../../core/functions/convertions"

export type TConfirmDialogButton = {
  title: string
  type: "negative" | "positive"
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  event?: "button" | "submit" | "reset"
  isDisabled?: boolean
  onClick?: () => void
}

interface IDialogActionButton {
  button: TConfirmDialogButton
  disabled?: boolean
  fullWidth?: boolean
}

const DialogActionButton = ({
  button,
  disabled,
  fullWidth,
}: IDialogActionButton) => {
  const {
    title,
    type,
    startIcon,
    endIcon,
    event = "button",
    isDisabled = false,
    onClick,
  } = button

  const buttons = {
    ["negative"]: (
      <NegativeButton
        key={`${Convertions.replaceSpacesAndNewlines(title)}-${type}`}
        fullWidth={fullWidth}
        variant="outlined"
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || isDisabled}
        onClick={onClick}
        type={event}
        sx={{ textTransform: "none" }}
      >
        {title}
      </NegativeButton>
    ),
    ["positive"]: (
      <Button
        key={`${Convertions.replaceSpacesAndNewlines(title)}-${type}`}
        fullWidth={fullWidth}
        variant="contained"
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || isDisabled}
        onClick={onClick}
        type={event}
        sx={{ textTransform: "none" }}
      >
        {title}
      </Button>
    ),
  }

  return buttons[type]
}

export default DialogActionButton
