// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBV0lrVRTmxj_niw-hfYS8gTmoo94Gz1kc",
  authDomain: "affiliatespayapp.firebaseapp.com",
  projectId: "affiliatespayapp",
  storageBucket: "affiliatespayapp.firebasestorage.app",
  messagingSenderId: "470538413199",
  appId: "1:470538413199:web:5bbf7a985cc66bdd0d3cfa",
  measurementId: "G-1Z4D4QC1HM",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig, "pay.celetus.com")
const analytics = getAnalytics(app)

export const analyticsPageView = () => {
  logEvent(analytics, "page_view", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: window.location.pathname,
  })
}

export const onErrorAnalytics = (
  message: string | Event,
  source: string | undefined,
  lineno: number | undefined,
  colno: number | undefined,
  error: Error | undefined
) => {
  const data = {
    message: message.toString(),
    source,
    line: lineno,
    column: colno,
    stack: error?.stack || "No stack trace",
  }
  logEvent(analytics, "exception", {
    description: message.toString(),
  })
  logEvent(analytics, "error", data)
}

export const EVENT_LISTENER_TYPE_UNHANDLED_REJECTION = "unhandledrejection"

export const onEventListenerUnhandledRejection = (
  event: PromiseRejectionEvent
) => {
  logEvent(analytics, "unhandled_rejection", {
    reason: event.reason || "No reason provided",
  })
}
