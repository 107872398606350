import { useCallback, useContext, useEffect, useRef, useState } from "react"
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Paper,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { TSale, TSaleResult } from "../../core/types/Sale"
import OrderController from "../../core/controllers/OrderController"
import {
  ICalculateInstallment,
  IOrderCharge,
  IOrderChargeStatus,
  IOrderResponse,
} from "../../core/types/Order"
import {
  maskCardNumber,
  maskCepNumber,
  maskCnpjNumber,
  maskCpfNumber,
  maskMonthYear,
  maskPhoneNumber,
  numberOnly,
} from "../../masks/masks"
import {
  initialCheckoutList,
  isWidget,
  methodTypeBoleto,
  methodTypeCreditCard,
  methodTypeDebitCard,
  methodTypePix,
  paymentsFailed,
  paymentsPaid,
} from "../../core/constants"
import CepController from "../../core/controllers/CepController"
import { IOrderBump } from "../../core/types/OrderBump"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TPriceAffiliated,
  TProductLinkAffiliatedCookie,
  TProductTypePayment,
} from "../../core/types/Product"
import { IErrorResponse } from "../../core/types/ErrorResponse"
import React from "react"
import TagFunctions, { ITagsProp } from "../../core/functions/TagFunctions"
import Validations from "../../core/functions/validations"
import ProductController from "../../core/controllers/ProductController"
import OrderBumpController from "../../core/controllers/OrderBumpController"
import { TLostSale } from "../../core/types/LostSale"
import LostSaleController from "../../core/controllers/LostSaleController"
import { useQuery } from "../../components/Query/useQuery"
import { useLocalStorage } from "../../core/hooks/useLocalStorage"
import Convertions from "../../core/functions/convertions"
import InfoDialog from "../../components/Dialogs/InfoDialog"
import TermsPurchaseDialog from "./TermsPurchase"
import LoadingBackdrop from "../../components/Loading/LoadingBackdrop"
import CheckoutSendingBackdrop from "../../components/Checkout/CheckoutSendingBackdrop"
import {
  TCheckoutAccess,
  TCheckoutComponent,
  TCheckoutInstallmentsContext,
  TCheckoutLayout,
} from "../../core/types/Checkout"
import CheckoutFunctions from "../../core/functions/CheckoutFunctions"
import Hosts from "../../core/functions/HostFunctions"
import MetaFunctions from "../../core/functions/MetaFunctions"
import KondutoFunctions from "../../core/functions/KondutoFunctions"
import Cookies from "js-cookie"
import BrowserFunctions from "../../core/functions/BrowserFunctions"
import CheckoutComponenetList from "../../components/CustomCheckout/CheckoutComponentList"
import GoogleFunctions from "../../core/functions/GoogleFunctions"
import { diffHours } from "../../core/functions/dateTime"
import HostFunctions from "../../core/functions/HostFunctions"
import VoucherController from "../../core/controllers/VoucherController"
import { TProductVoucherValue } from "../../core/types/Voucher"
import NumberFunctions from "../../core/functions/NumberFunctions"
import { CheckoutContext } from "../../core/contexts/CheckoutContext"
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import "./Checkout.css"
import {
  TCustomCheckoutComponentDevice,
  TModelType,
  TPopupConfig,
} from "../../core/types/CustomCheckout"
import ExitPopupDialog from "./ExitPopup"
import {
  EVENT_LISTENER_TYPE_UNHANDLED_REJECTION,
  analyticsPageView,
  onErrorAnalytics,
  onEventListenerUnhandledRejection,
} from "../../services/firebaseConfig"

const CheckoutPage: React.FC = () => {
  console.log("CheckoutPage")
  let { codeId, checkoutOrderCodeId } = useParams<{
    codeId?: string
    checkoutOrderCodeId?: string
  }>()

  if (codeId === undefined || codeId === null || codeId === "") {
    const widgetCodeId = localStorage.getItem("codeId")
    if (widgetCodeId) {
      codeId = widgetCodeId
    }
  }

  console.log({ codeId, checkoutOrderCodeId })

  const {
    checkoutSettings,
    setCheckoutSettings,
    productPrice: data,
    setProductPrice: setData,
    installment,
    setInstallment,
  } = useContext(CheckoutContext)

  const sessionId = useRef<string>(
    Math.floor(new Date().getTime() / 1000).toString()
  )
  console.log({ sessionId })
  const tagFacebook = useRef<ITagsProp | null>(null)
  const tagGoogleAds = useRef<ITagsProp[] | null>(null)
  const tagInitialDataLayer = useRef<ITagsProp | null>(null)
  const tagDataLayer = useRef<ITagsProp | null>(null)
  const tagGTM = useRef<ITagsProp | null>(null)
  const metasKonduto = useRef<HTMLMetaElement[]>([])
  const productLinkAffiliated = useRef<
    TProductLinkAffiliatedCookie | undefined
  >()
  const isPreviewedExitPopup = useRef(false)
  const voucherType = useRef<"voucher" | "discount">("voucher")

  const query = useQuery()

  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [viewLoading, setViewLoading] = useState(false)
  const [waitForApproval, setWaitForApproval] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingVoucher, setLoadingVoucher] = useState(false)
  const [searchedZipCode, setSearchedZipCode] = useState(false)
  const [openZipCodeInvalid, setOpenZipCodeInvalid] = useState(false)
  const [openTermsPurchase, setOpenTermsPurchase] = useState(false)
  const [successVoucher, setSuccessVoucher] = useState(false)
  const [isExitPopup, setIsExitPopup] = useState(false)

  const [errorVoucher, setErrorVoucher] = useState("")

  const [affiliated, setAffiliated] = useState<TPriceAffiliated | undefined>(
    undefined
  )
  const [dataResult, setDataResult] = useState<IOrderResponse | undefined>(
    undefined
  )
  const [chargeId, setChargeId] = useState<string | undefined>(undefined)
  const [checkout, setCheckout] = useState<TSaleResult | undefined>(undefined)

  const [orderBumps, setOrderBumps] = useState<IOrderBump[]>([])

  const [list, setList] = useState([...initialCheckoutList])
  const [exitPopup, setExitPopup] = useState<TPopupConfig | undefined>()

  const [error, setError] = useState("")
  const [errorPrice, setErrorPrice] = useState("")

  const [, setSaleStorage] = useLocalStorage("afpa:sale", null)

  const navigate = useNavigate()

  const onlyProducer = window.location.pathname.startsWith("/pro/")

  const isNewLayoutCoverImage = CheckoutFunctions.isNewLayoutCoverImage(
    data?.checkoutComponents
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setFocus,
    clearErrors,
  } = useForm<TSale>({
    defaultValues: CheckoutFunctions.getSaleDefault(
      query,
      data?.id,
      onlyProducer
    ),
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const checkoutOrderIdValue = watch("checkoutOrderId")
  const nameValue = watch("name")
  const emailValue = watch("email")
  const emailConfirmValue = watch("emailConfirm")
  const phoneValue = watch("phone")
  const documentValue = watch("document")
  const zipCodeValue = watch("address.zipCode")
  const paymentMethodType = watch("payment.methodType")
  const paymentCardNumber = watch("payment.cardNumber")
  const paymentCardValidate = watch("payment.cardValidate")
  const paymentInstallment = watch("payment.installment")
  const paymentTotal = watch("payment.total")
  const paymentValue = watch("payment.value")
  const voucherIdentifier = watch("payment.voucherIdentifier")
  const voucherValueType = watch("payment.voucherType")
  const voucherValue = watch("payment.voucherValue")
  const voucherStatus = watch("payment.voucherStatus")
  const products = watch("products")
  const pageAccessIdValue = watch("pageAccessId")
  const clientIpValue = watch("clientIP")

  const fieldSetFocus = useRef(false)
  const id = useRef("")
  const searched = useRef(false)
  const disableFocus = useRef(false)
  const lostSaleCodeId = useRef<string>()
  const lostSaleSending = useRef(false)
  const accessPageDate = useRef<Date | null>(null)

  const getClientIp = useCallback(async () => {
    if ((clientIpValue ?? "") === "") {
      const ipAddress = await HostFunctions.getIpAddress()
      setValue("clientIP", ipAddress)
      return ipAddress
    }
    return clientIpValue ?? ""
  }, [clientIpValue, setValue])

  const updatePaymentValues = useCallback(
    (
      numberInstallments: number,
      installments?: IProductPricePaymentInstallment[]
    ) => {
      if (paymentMethodType === methodTypeCreditCard) {
        const installment = installments?.find(
          (x) => x.installmentNumber === numberInstallments
        )
        if (installment) {
          const discount = NumberFunctions.round(
            voucherStatus === "valid" && voucherValue
              ? voucherValueType === TProductVoucherValue.value
                ? voucherValue
                : (installment.total * voucherValue) / 100
              : 0
          )

          const installmentDiscount = NumberFunctions.round(
            discount / installment.installmentNumber
          )
          const value = NumberFunctions.round(
            installment.value - installmentDiscount
          )
          const subtotal = NumberFunctions.round(installment.total)
          const total = NumberFunctions.round(subtotal - discount)
          setValue("payment.value", value)
          setValue("payment.subtotal", subtotal)
          setValue("payment.discount", discount)
          setValue("payment.total", total)
        }
      } else if (data) {
        const value = NumberFunctions.round(
          data?.firstPurchasePrice ?? data?.value ?? 0
        )
        const total = NumberFunctions.round(
          value +
            products.reduce(
              (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
              0
            )
        )
        const discount = NumberFunctions.round(
          voucherStatus === "valid" && voucherValue
            ? voucherValueType === TProductVoucherValue.value
              ? voucherValue
              : (total * voucherValue) / 100
            : 0
        )

        setValue("payment.value", value)
        setValue("payment.subtotal", total)
        setValue("payment.discount", discount)
        setValue("payment.total", total - discount)
      }
    },
    [data, paymentMethodType, voucherValueType, voucherValue, setValue]
  )

  const handleLoadCalculateInstallments = useCallback(
    async (
      newValue: number,
      mainOnly: boolean,
      installment: TCheckoutInstallmentsContext | undefined
    ) => {
      if ((installment?.main ?? []).length <= 0) {
        return
      }

      setSending(true)
      try {
        const response = await OrderController.calculeIsntallments({
          priceId: id.current,
          newValue,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ICalculateInstallment

        if (responseError.code) {
          setError(responseError.error)
        } else {
          if (mainOnly) {
            const respOriginal = await OrderController.calculeIsntallments({
              priceId: id.current,
              newValue: data?.value ?? 0,
            })
            const originData = respOriginal as ICalculateInstallment
            if (originData.installments) {
              setInstallment({
                main: responseData.installments,
                checkout: responseData.installments,
                original: originData.installments,
              })
            }
          } else {
            setInstallment({
              main: installment?.main ?? responseData.installments,
              checkout: responseData.installments,
              original: installment?.original,
            })
          }

          updatePaymentValues(paymentInstallment, responseData.installments)
        }
      } finally {
        setSending(false)
      }
    },
    [paymentInstallment, updatePaymentValues]
  )

  const orderBumpChange = useCallback(
    async (orderBump: IOrderBump) => {
      let newValue = data?.firstPurchasePrice || data?.value || 0

      if (orderBump.selected) {
        const orderBumpValue =
          orderBump.productPrice?.firstPurchasePrice ||
          orderBump.productPrice?.value ||
          0
        var installments: IProductPricePaymentInstallment[] = []

        if ((installment?.main ?? []).length > 0) {
          if (!orderBump.installments || orderBump.installments.length <= 0) {
            const response = await OrderController.calculeIsntallments({
              priceId: id.current,
              newValue: orderBumpValue,
            })
            const responseError = response as IErrorResponse
            const responseData = response as ICalculateInstallment

            if (responseError.code) {
              setError(responseError.error)
              return
            } else {
              installments = responseData.installments
            }
          } else {
            installments = orderBump.installments ?? []
          }
        }

        products.push({
          id: "",
          productId: orderBump.productPrice?.product?.id ?? "",
          productPriceId: orderBump.productPrice?.id ?? "",
          productName: orderBump.productPrice?.product?.name ?? "",
          productCoverUrl: orderBump.productPrice?.product?.coverUrl ?? "",
          amount: 1,
          value: orderBump.productPrice?.value ?? 0,
          installments,
          typePayment:
            orderBump.productPrice?.product?.typePayment ??
            TProductTypePayment.OneTimePayment,
          frequency: orderBump.productPrice?.frequency,
          firstPurchasePrice: orderBump.productPrice?.firstPurchasePrice,
        })
        setValue("products", products)

        newValue =
          (data?.firstPurchasePrice ?? data?.value ?? 0) +
          products.reduce(
            (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
            0
          )
      } else {
        const newProducts = products.filter(
          (product) => product.productPriceId !== orderBump.productPrice?.id
        )
        setValue("products", newProducts)
        newValue =
          (data?.firstPurchasePrice ?? data?.value ?? 0) +
          newProducts.reduce(
            (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
            0
          )
      }

      await handleLoadCalculateInstallments(newValue, false, installment)
    },
    [
      data?.value,
      handleLoadCalculateInstallments,
      products,
      setValue,
      installment,
    ]
  )

  const updateCustomCheckoutSettings = useCallback((device?: any) => {
    if (device && device !== "null") {
      const obj = JSON.parse(device) as TCustomCheckoutComponentDevice
      setCheckoutSettings(obj.settings)
      setList(obj.components)
      setExitPopup(obj.popupConfig)
    }
  }, [])

  const updateCustomCheckout = useCallback(
    (productData: IProductPrice) => {
      if (productData.customCheckout) {
        if (isMobile) {
          updateCustomCheckoutSettings(productData.customCheckout.mobileLayout)
        } else {
          updateCustomCheckoutSettings(productData.customCheckout.desktopLayout)
        }
      }
    },
    [isMobile, setCheckoutSettings, setList]
  )

  useEffect(() => {
    if (searched && checkout) {
      const { address, products: checkoutProducts } = checkout

      setValue("checkoutOrderId", checkout.checkoutOrderId)
      setValue("productPriceId", checkout.productPriceId)
      setValue("name", checkout.name)
      setValue("document", checkout.document)
      setValue("email", checkout.email)
      setValue("emailConfirm", checkout.email)
      setValue("phone", checkout.phone)

      setTimeout(() => {
        setValue("address.city", address.city)
        setValue("address.neighborhood", address.neighborhood)
        setValue("address.street", address.street)
        setValue("address.number", address.number)
        setValue("address.state", address.state)
        setValue("address.complement", address.complement)

        disableFocus.current = false
      }, 2000)

      checkoutProducts.map((product) => {
        const orderBump = orderBumps.find(
          (ob) => ob.productPriceId === product.productPriceId
        )
        if (orderBump) {
          orderBump.selected = true
          orderBumpChange(orderBump)
        }
        return true
      })

      setCheckout(undefined)
    }
  }, [checkout, setValue, setFocus, orderBumps, orderBumpChange])

  const sendPixelsInitiateCheckoutData = () => {
    //sendCheckoutPixels(data);
  }

  const sendPixelsInitiateCheckout = (
    productPrice: IProductPrice | null,
    priceAffiliated: TPriceAffiliated | undefined
  ) => {
    if (productPrice && priceAffiliated && priceAffiliated.pixels.length > 0) {
      if (tagFacebook.current) {
        return
      }

      try {
        tagFacebook.current = MetaFunctions.addTagInitiateCheckout(
          sessionId.current,
          productPrice.codeId ?? "",
          productPrice.productName ?? "",
          productPrice.value ?? 0.0,
          priceAffiliated.pixels,
          emailValue,
          nameValue,
          phoneValue,
          documentValue,
          watch("address.city"),
          watch("address.state"),
          watch("address.zipCode")
        )
      } catch (e) {
        console.error(e)
      }

      try {
        MetaFunctions.sendCheckoutAPI(
          sessionId.current,
          codeId,
          productPrice.value ?? 0,
          priceAffiliated.pixels,
          emailValue,
          nameValue,
          phoneValue,
          documentValue,
          watch("address.city"),
          watch("address.state"),
          watch("address.zipCode")
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  const sendPixelsAddPaymentInfo = (
    productPrice: IProductPrice | null,
    priceAffiliated: TPriceAffiliated | undefined
  ) => {
    if (productPrice && priceAffiliated && priceAffiliated.pixels.length > 0) {
      const addPaymentInfoId = Math.floor(
        new Date().getTime() / 1000
      ).toString()

      CheckoutFunctions.setUserCookie(
        emailValue,
        nameValue,
        phoneValue,
        documentValue,
        watch("address.city"),
        watch("address.state"),
        watch("address.zipCode")
      )

      try {
        tagFacebook.current = MetaFunctions.addTagAddPaymentInfo(
          addPaymentInfoId,
          productPrice.codeId ?? "",
          productPrice.productName ?? "",
          productPrice.value ?? 0.0,
          Convertions.methodTypeToPaymentMethod(paymentMethodType),
          priceAffiliated.pixels,
          emailValue,
          nameValue,
          phoneValue,
          documentValue,
          watch("address.city"),
          watch("address.state"),
          watch("address.zipCode")
        )
      } catch (e) {
        console.error(e)
      }

      try {
        MetaFunctions.sendAddPaymentInfoAPI(
          addPaymentInfoId,
          codeId,
          productPrice.value ?? 0,
          Convertions.methodTypeToPaymentMethod(paymentMethodType),
          priceAffiliated.pixels,
          emailValue,
          nameValue,
          phoneValue,
          documentValue,
          watch("address.city"),
          watch("address.state"),
          watch("address.zipCode")
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  const registerAccess = async (
    price: IProductPrice | null,
    lostSaleCode: string | undefined
  ) => {
    if (price) {
      try {
        const infos = BrowserFunctions.getInfo()
        const clientIp = await getClientIp()
        const { fbp, fbc } = MetaFunctions.getFbpAndFbc()

        const access: TCheckoutAccess = {
          tenantId: price.tenantId ?? "",
          tenantName: price.tenantName ?? "",
          productId: price.productId ?? "",
          productPriceId: price.id,
          productCode: codeId ?? "",
          productName: price.productName ?? "",
          checkoutModelId: price.checkoutModelId ?? "",
          checkoutModelName: price.checkoutModelName ?? "",
          affiliatedCode: query.get("afid") ?? undefined,
          clientIp,
          userAgent: infos.userAgent,
          browser: infos.browser,
          language: infos.language,
          webdriver: infos.webdriver,
          cookieEnabled: infos.cookieEnabled,
          lostSaleCode,
          pageUrl: window.location.href,
          fbclid: query.get("fbclid"),
          fbp,
          fbc,
        }
        const response = await OrderController.registerAccess({
          data: access,
        })
        setValue("pageAccessId", response.data?.id)
        setValue("sessionToken", response.data?.token)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const getOrderBumpInstallments = useCallback(
    async (orderBump: IOrderBump) => {
      const orderBumpValue =
        orderBump.productPrice?.firstPurchasePrice ??
        orderBump.productPrice?.value ??
        0
      var installments: IProductPricePaymentInstallment[] = []

      const response = await OrderController.calculeIsntallments({
        priceId: id.current,
        newValue: orderBumpValue,
      })
      const responseError = response as IErrorResponse
      const responseData = response as ICalculateInstallment

      if (!responseError.code) {
        installments = responseData.installments
      }

      orderBump.installments = installments
      return orderBump
    },
    [installment]
  )

  const getOrderBumpInstallmentsAll = useCallback(
    async (list: IOrderBump[]) => {
      const newOrderBumps: IOrderBump[] = []

      for (var i = 0; i < list.length; i++) {
        const order = await getOrderBumpInstallments(list[i])
        newOrderBumps.push({ ...order })
      }
      setOrderBumps([...newOrderBumps])
    },
    [orderBumps, setOrderBumps, getOrderBumpInstallments]
  )

  const getOrderBumps = useCallback(
    async (code?: string): Promise<void> => {
      try {
        if (code && code !== "") {
          const responseOrder = await OrderBumpController.getByProductPriceId({
            productPriceId: id.current,
          })
          const responseErrorOrder = responseOrder as IErrorResponse
          const responseDataOrder = responseOrder as IOrderBump[]

          if (responseErrorOrder.code) {
            setError(responseErrorOrder.error)
          } else {
            getOrderBumpInstallmentsAll(responseDataOrder)
          }
        }
      } catch (e) {
        console.error(e)
        var message = "Erro não identificado"
        if (typeof e === "string") {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      }
    },
    [id, setError, setOrderBumps]
  )

  const getAffiliated = useCallback(
    async (code?: string): Promise<void> => {
      if (code && code !== "") {
        try {
          let afid = query.get("afid")
          let plid = query.get("pa")
          let afcd: Date | null | undefined

          if (!afid || afid === "") {
            const cookieOther = Cookies.get("__apop")
            if (cookieOther) {
              try {
                productLinkAffiliated.current = JSON.parse(
                  cookieOther
                ) as TProductLinkAffiliatedCookie

                const linkCreated = new Date(productLinkAffiliated.current.cre)
                const diffInHours = diffHours(new Date(), linkCreated)

                if (diffInHours <= 1 && productLinkAffiliated.current) {
                  afid = productLinkAffiliated.current.afid
                  afcd = linkCreated
                }
              } catch (e) {}
            }
          }

          if (!afid || afid === "") {
            const cookieValue = Cookies.get(`_ap_${code}`)
            if (cookieValue) {
              try {
                productLinkAffiliated.current = JSON.parse(
                  cookieValue
                ) as TProductLinkAffiliatedCookie
                if (productLinkAffiliated.current) {
                  afid = productLinkAffiliated.current.afid
                  afcd = new Date(productLinkAffiliated.current.cre)
                }
              } catch (e) {}
            }
          }

          const clientIp = await getClientIp()
          const response = await ProductController.getPriceAffiliatedByCode({
            priceCode: code,
            afid,
            plid,
            afcd,
            clientIp,
            onlyProducer,
          })
          const responseError = response as IErrorResponse
          const responseData = response as TPriceAffiliated

          if (!responseError.code) {
            setAffiliated(responseData)
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    [productLinkAffiliated, setData]
  )

  const getProductPrice = useCallback(
    async (code?: string, checkoutCode?: string): Promise<void> => {
      if (Hosts.isHostGoAffiliatesPay()) {
        return
      }

      if (searched.current) {
        return
      }
      searched.current = true
      try {
        setLoading(true)

        // identificação das tags via url da api de conversão do facebook
        const _fbp = query.get("fbp") ?? query.get("_fbp") ?? ""
        const _fbc = query.get("fbc") ?? query.get("_fbc") ?? ""
        const _fbclid = query.get("fbclid") ?? query.get("_fbclid") ?? ""
        Cookies.set("_apfbp", _fbp)
        Cookies.set("_apfbc", _fbc)
        Cookies.set("_apfbclid", _fbclid)

        if (error !== "") {
          setErrorPrice("")
        }

        if (checkoutCode && checkoutCode !== "") {
          const checkout = await OrderController.getCheckoutOrder({
            checkoutOrderCodeId: checkoutCode,
            afid: query.get("afid"),
          })
          setCheckout(checkout)

          if (checkout) {
            const { address, productPrice, orderBumps: bumps } = checkout

            id.current = productPrice?.id || ""
            disableFocus.current = true

            if (productPrice) {
              setData(productPrice)
            }

            setOrderBumps(bumps)

            setValue("address.zipCode", address.zipCode)
            registerAccess(productPrice, lostSaleCodeId.current)
          }
        }

        if (code && code !== "") {
          let afid = query.get("afid")
          let plid = query.get("pa")
          let afcd: Date | null | undefined

          if (!afid || afid === "") {
            // validar link de outros produtos que expira em 1 hora
            const cookieOther = Cookies.get("__apop")
            if (cookieOther) {
              try {
                productLinkAffiliated.current = JSON.parse(
                  cookieOther
                ) as TProductLinkAffiliatedCookie

                const linkCreated = new Date(productLinkAffiliated.current.cre)
                const diffInHours = diffHours(new Date(), linkCreated)

                if (diffInHours <= 1 && productLinkAffiliated.current) {
                  afid = productLinkAffiliated.current.afid
                  afcd = linkCreated
                }
              } catch (e) {}
            }
          }

          if (!afid || afid === "") {
            const cookieValue = Cookies.get(`_ap_${code}`)
            if (cookieValue) {
              try {
                productLinkAffiliated.current = JSON.parse(
                  cookieValue
                ) as TProductLinkAffiliatedCookie
                if (productLinkAffiliated.current) {
                  afid = productLinkAffiliated.current.afid
                  afcd = new Date(productLinkAffiliated.current.cre)
                }
              } catch (e) {}
            }
          }

          const model = CheckoutFunctions.getModelByParams(query)

          const response = await ProductController.getPaymentByPrice({
            priceCode: code,
            afid,
            plid,
            afcd,
            clientIp: "",
            model,
          })
          const responseError = response as IErrorResponse
          const responseData = response as IProductPrice

          if (responseError.code) {
            setErrorPrice(responseError.error)
          } else {
            id.current = responseData.id
            setData(responseData)
            setLoading(false)
            await getAffiliated(codeId)
            await getOrderBumps(codeId)
            await registerAccess(responseData, lostSaleCodeId.current)
          }
        }
      } catch (e) {
        var message = "Erro não identificado"
        if (typeof e === "string") {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        console.error(message)
        console.error(e)
      } finally {
        if (loading) setLoading(false)
      }
    },
    [
      id,
      query,
      error,
      searched,
      productLinkAffiliated,
      diffHours,
      getAffiliated,
      getOrderBumps,
      setLoading,
      setErrorPrice,
      setCheckout,
      setData,
      setOrderBumps,
      setValue,
      registerAccess,
    ]
  )

  const searchZipCode = useCallback(
    async (load: boolean, zipcode?: string) => {
      if (!zipcode || load) {
        return
      }
      const code = numberOnly(zipcode)
      if (code.length < 8) {
        return
      }
      setSending(true)
      try {
        const cep = await CepController.search(code)
        if (cep) {
          setValue("address.street", cep.logradouro)
          setValue("address.complement", cep.complemento)
          setValue("address.neighborhood", cep.bairro)
          setValue("address.city", cep.localidade)
          setValue("address.state", cep.uf)

          if (!disableFocus.current) {
            setTimeout(() => {
              if (!disableFocus.current) {
                if (cep.logradouro === "") {
                  setFocus("address.street", {
                    shouldSelect: true,
                  })
                } else {
                  setFocus("address.number", {
                    shouldSelect: true,
                  })
                }
              }
            }, 1000)
          }
        } else {
          setOpenZipCodeInvalid(true)
        }
      } finally {
        setSending(false)
        setSearchedZipCode(true)
      }
    },
    [setFocus, setValue, setOpenZipCodeInvalid]
  )

  const verifiedCheckoutChargeSuccess = useCallback(
    (charge: IOrderCharge, path: "thanks" | "success") => {
      setWaitForApproval(false)
      setSuccess(true)
      setViewLoading(false)

      const sale: TSale = watch() as TSale
      const query = CheckoutFunctions.getQuerySuccessPage(
        codeId ?? "",
        data!,
        affiliated!,
        sale,
        dataResult!,
        onlyProducer,
        charge
      )
      Hosts.openNavigate(`/${codeId}/${path}/redirect${query}`, navigate)
    },
    [
      codeId,
      data,
      affiliated,
      dataResult,
      onlyProducer,
      navigate,
      setWaitForApproval,
      setSuccess,
      setViewLoading,
    ]
  )

  const verifiedCheckoutCharge = useCallback(async () => {
    var verifiedCheckoutChargeInterval = setInterval(async () => {
      if (waitForApproval) {
        const charge = await OrderController.verified({ chargeId })
        if (charge && charge.status !== IOrderChargeStatus.Processing) {
          if ([methodTypePix, methodTypeBoleto].includes(paymentMethodType)) {
            if (
              charge.pixQrCode ||
              charge.pixQrUrl ||
              charge.boletoUrl ||
              charge.boletoCodeBar ||
              charge.boletoQrCode
            ) {
              clearInterval(verifiedCheckoutChargeInterval)
              verifiedCheckoutChargeSuccess(charge, "thanks")
            }
          } else if (paymentsPaid.includes(charge.status)) {
            clearInterval(verifiedCheckoutChargeInterval)
            verifiedCheckoutChargeSuccess(charge, "success")
          } else if (paymentsFailed.includes(charge.status)) {
            clearInterval(verifiedCheckoutChargeInterval)
            setSaleStorage(null)
            setWaitForApproval(false)
            setViewLoading(false)
            setError(Convertions.getErrorPayment(paymentMethodType))
          }
        }
      }
    }, 3000)
  }, [
    waitForApproval,
    chargeId,
    codeId,
    data,
    affiliated,
    dataResult,
    onlyProducer,
    watch,
    navigate,
    setSaleStorage,
    setWaitForApproval,
    setViewLoading,
    setError,
  ])

  const sendApplyVoucher = useCallback(
    async (identifier: string | undefined | null) => {
      if (identifier) {
        setLoadingVoucher(true)
        try {
          const response = await VoucherController.applyVoucher({
            productId: data?.productId ?? "",
            productPriceId: data?.id ?? "",
            voucherIdentifier: identifier,
          })
          if (response.success && response.data) {
            setValue("payment.voucherStatus", "valid")
            setValue("payment.voucherId", response.data.voucherId)
            setValue(
              "payment.voucherIdentifier",
              response.data.voucherIdentifier
            )
            setValue("payment.voucherValue", response.data.value)
            setValue("payment.voucherType", response.data.valueType)
            setSuccessVoucher(true)
          } else {
            setValue("payment.voucherStatus", "invalid")
            setErrorVoucher("Cupom inválido!")
          }
        } finally {
          setLoadingVoucher(false)
        }
      }
    },
    [data, setLoadingVoucher]
  )

  const applyVoucher = (voucherCode: string) => {
    voucherType.current = "discount"
    setValue("payment.voucherIdentifier", voucherCode)
    sendApplyVoucher(voucherCode)
  }

  const showExitPopupMobile = useCallback(() => {
    const isShowPreviewedExitPopup =
      isMobile &&
      exitPopup &&
      exitPopup.modelType !== TModelType.none &&
      !isPreviewedExitPopup.current

    if (isShowPreviewedExitPopup) {
      isPreviewedExitPopup.current = true
      setIsExitPopup(true)
    }
  }, [isMobile, exitPopup, isPreviewedExitPopup, setIsExitPopup])

  const handleMouseleave = (event: any) => {
    const mouseY = event.clientY
    if (mouseY < 0) {
      const isExitPopup = exitPopup && exitPopup.modelType !== TModelType.none
      const isShowPreviewedExitPopup =
        !isMobile && isExitPopup && !isPreviewedExitPopup.current

      if (isShowPreviewedExitPopup) {
        isPreviewedExitPopup.current = true
        setIsExitPopup(true)
      }
    }
  }

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      showExitPopupMobile()
    }
  }, [
    isMobile,
    exitPopup,
    isPreviewedExitPopup,
    setIsExitPopup,
    showExitPopupMobile,
  ])

  useEffect(() => {
    if (emailValue.includes(" ")) {
      const email = emailValue.replace(" ", "")
      setValue("email", email)
    }
  }, [emailValue, setValue])

  useEffect(() => {
    if (emailConfirmValue.includes(" ")) {
      const email = emailConfirmValue.replace(" ", "")
      setValue("emailConfirm", email)
    }
  }, [emailConfirmValue, setValue])

  useEffect(() => {
    const phoneComplete = maskPhoneNumber(phoneValue)
    setValue("phone", phoneComplete)
    if (phoneComplete.length > 14) {
      nextInput()
    }
  }, [phoneValue, setValue])

  const nextInputCpfCnpj = () => {
    if (CheckoutFunctions.isLayout5ModelE(data?.checkoutComponents)) {
      setFocus("phone")
    } else {
      nextInput()
    }
  }

  useEffect(() => {
    const documentNumberOnly = numberOnly(documentValue)
    if (documentNumberOnly.length > 11) {
      const documentComplete = maskCnpjNumber(documentValue)
      setValue("document", documentComplete)

      if (documentNumberOnly.length > 13) {
        if (Validations.CNPJ(documentValue)) {
          nextInputCpfCnpj()
        }
      }
    } else {
      const documentComplete = maskCpfNumber(documentValue)
      setValue("document", documentComplete)

      if (documentNumberOnly.length > 10) {
        if (Validations.CPF(documentValue)) {
          nextInputCpfCnpj()
        }
      }
    }
  }, [documentValue, setValue])

  useEffect(() => {
    const zipcode = maskCepNumber(zipCodeValue)
    setValue("address.zipCode", zipcode)
    searchZipCode(loading, zipcode)
  }, [zipCodeValue, setValue, searchZipCode, loading])

  useEffect(() => {
    const cardNumberComplete = maskCardNumber(paymentCardNumber)
    setValue("payment.cardNumber", cardNumberComplete)
    if (cardNumberComplete.length > 18) {
      nextInput()
    }
  }, [paymentCardNumber, setValue])

  const layout = CheckoutFunctions.getLayoutComponent(
    TCheckoutComponent.ClientsData,
    data?.checkoutComponents
  )

  useEffect(() => {
    const cardValidateComplete = maskMonthYear(paymentCardValidate)
    setValue("payment.cardValidate", cardValidateComplete)
    if (cardValidateComplete.length > 4) {
      if (layout === TCheckoutLayout.Layout5) return
      nextInput()
    }
  }, [paymentCardValidate, setValue])

  useEffect(() => {
    if (data) {
      if (!tagDataLayer.current) {
        tagDataLayer.current = GoogleFunctions.addDataLayerBeginCheckout(data)
      }
      if (!tagGTM.current) {
        tagGTM.current = GoogleFunctions.addGoogleTagManager(
          data.googleTagManagerId
        )
      }

      if (metasKonduto.current.length <= 0) {
        metasKonduto.current = KondutoFunctions.addTagMetaCheckoutProduct(
          codeId || "",
          data?.productName || ""
        )
      }

      const voucherCode = CheckoutFunctions.getVoucherAuto(query)
      if (voucherCode) applyVoucher(voucherCode)
    }

    return () => {
      if (tagDataLayer.current) {
        TagFunctions.removeTags(tagDataLayer.current)
      }
      if (tagGTM.current) {
        TagFunctions.removeTags(tagGTM.current)
      }
    }
  }, [data])

  useEffect(() => {
    if (affiliated) {
      sendPixelsInitiateCheckout(data, affiliated)

      if (!tagGoogleAds.current) {
        tagGoogleAds.current = GoogleFunctions.addTagGoogleAdsPixelCheckout(
          affiliated.pixels
        )
      }

      const affiliateCodeValue = watch("affiliateCode")
      if (!affiliateCodeValue || affiliateCodeValue === "") {
        if (
          affiliated?.affiliated &&
          affiliated.affiliated.affiliatedCodeId !== ""
        ) {
          setValue("affiliateCode", affiliated.affiliated.affiliatedCodeId)

          if (productLinkAffiliated.current) {
            if (
              productLinkAffiliated.current.afid ===
              affiliated.affiliated.affiliatedCodeId
            ) {
              if (productLinkAffiliated.current.src) {
                setValue("src", productLinkAffiliated.current.src)
              }
              if (productLinkAffiliated.current.sck) {
                setValue("sck", productLinkAffiliated.current.sck)
              }
              if (productLinkAffiliated.current.utm_campaign) {
                setValue(
                  "utm_campaign",
                  productLinkAffiliated.current.utm_campaign
                )
              }
              if (productLinkAffiliated.current.utm_content) {
                setValue(
                  "utm_content",
                  productLinkAffiliated.current.utm_content
                )
              }
              if (productLinkAffiliated.current.utm_medium) {
                setValue("utm_medium", productLinkAffiliated.current.utm_medium)
              }
              if (productLinkAffiliated.current.utm_source) {
                setValue("utm_source", productLinkAffiliated.current.utm_source)
              }
              if (productLinkAffiliated.current.utm_term) {
                setValue("utm_term", productLinkAffiliated.current.utm_term)
              }
            }
          }
        }
      }
    }
  }, [affiliated])

  useEffect(() => {
    if (data) {
      if (!isWidget()) {
        document.title = data?.productName ?? "Checkout Celetus"
      }

      analyticsPageView()
      let installmentNumber = 1

      if (paymentMethodType <= 0 && data && data.paymentMethods.length > 0) {
        const id = data.paymentMethods[0].methodType

        if (id === methodTypeCreditCard) {
          const payment = data?.paymentMethods.find(
            (x) => x.methodType === methodTypeCreditCard
          )
          if (payment) {
            setInstallment({
              main: installment?.main ?? payment.installments,
              checkout: payment.installments,
              original: installment?.original,
            })
          }

          payment?.installments.map((x) => {
            if (x.installmentNumber > installmentNumber) {
              installmentNumber = x.installmentNumber
            }
            return true
          })
        }
      }

      if (paymentMethodType <= 0) {
        if (data.paymentMethods && data.paymentMethods.length > 0) {
          const id = data.paymentMethods[0].methodType
          setValue("payment.methodType", id)
        }
      }

      const value = NumberFunctions.round(
        data?.firstPurchasePrice ?? data?.value ?? 0
      )
      const total = NumberFunctions.round(
        value +
          products.reduce(
            (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
            0
          )
      )
      const discount = NumberFunctions.round(
        voucherStatus === "valid" && voucherValue
          ? voucherValueType === TProductVoucherValue.value
            ? voucherValue
            : (total * voucherValue) / 100
          : 0
      )

      setValue("payment.value", value)
      setValue("payment.subtotal", total)
      setValue("payment.discount", discount)
      setValue("payment.total", total - discount)

      if (paymentInstallment <= 0) {
        setValue("payment.installment", installmentNumber)
      }

      updateCustomCheckout(data)
      handleLoadCalculateInstallments(total, true, installment)
    }
  }, [
    data,
    paymentInstallment,
    paymentMethodType,
    setValue,
    handleLoadCalculateInstallments,
  ])

  useEffect(() => {
    if (paymentMethodType === methodTypeCreditCard) {
      const installmentItem = installment?.checkout?.find(
        (x) => x.installmentNumber === paymentInstallment
      )
      if (installmentItem) {
        const discount = NumberFunctions.round(
          voucherStatus === "valid" && voucherValue
            ? voucherValueType === TProductVoucherValue.value
              ? voucherValue
              : (installmentItem.total * voucherValue) / 100
            : 0
        )

        const installmentDiscount = NumberFunctions.round(
          discount / installmentItem.installmentNumber
        )
        const value = NumberFunctions.round(
          installmentItem.value - installmentDiscount
        )
        const subtotal = NumberFunctions.round(installmentItem.total)
        const total = NumberFunctions.round(subtotal - discount)
        setValue("payment.value", value)
        setValue("payment.subtotal", subtotal)
        setValue("payment.discount", discount)
        setValue("payment.total", total)
      }
    } else if (data) {
      const value = NumberFunctions.round(
        data?.firstPurchasePrice ?? data?.value ?? 0
      )
      const total = NumberFunctions.round(
        value +
          products.reduce(
            (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
            0
          )
      )
      const discount = NumberFunctions.round(
        voucherStatus === "valid" && voucherValue
          ? voucherValueType === TProductVoucherValue.value
            ? voucherValue
            : (total * voucherValue) / 100
          : 0
      )

      setValue("payment.value", value)
      setValue("payment.subtotal", total)
      setValue("payment.discount", discount)
      setValue("payment.total", total - discount)
    }
  }, [
    data,
    installment,
    paymentInstallment,
    paymentMethodType,
    products,
    voucherStatus,
    voucherValue,
    voucherValueType,
    setValue,
  ])

  useEffect(() => {
    if (waitForApproval) {
      verifiedCheckoutCharge()
    }
  }, [verifiedCheckoutCharge, waitForApproval])

  useEffect(() => {
    window.onerror = onErrorAnalytics
    window.addEventListener(
      EVENT_LISTENER_TYPE_UNHANDLED_REJECTION,
      onEventListenerUnhandledRejection
    )

    getProductPrice(codeId, checkoutOrderCodeId)
    accessPageDate.current = new Date()

    if (Hosts.isHostGoAffiliatesPay()) {
      Hosts.openNavigate(
        `/${codeId}/redirect${window.location.search}`,
        navigate
      )
      return
    }

    const tagEventsKonduto = KondutoFunctions.addTagEventos()
    const tagCheckoutKonduto = KondutoFunctions.addTagMetaCheckout()

    const tagKonduto = TagFunctions.addScriptHeader(`
    var visitorID; 
    (function () {
      var period = 300;
      var limit = 20 * 1e3;
      var nTry = 0;
      var intervalID = setInterval(function () {
        var clear = limit / period <= ++nTry;
        if ((typeof (Konduto) !== "undefined") &&
          (typeof (Konduto.getVisitorID) !== "undefined")) {
          visitorID = window.Konduto.getVisitorID();
          localStorage.setItem("visitorID", visitorID);
          clear = true;
        }
        if (clear) {
          clearInterval(intervalID);
        }
      }, period);
    })(visitorID);`)

    if (!tagInitialDataLayer.current) {
      tagInitialDataLayer.current = GoogleFunctions.initialDataLayer()
    }

    return () => {
      saveLostSale()

      TagFunctions.removeMeta(tagCheckoutKonduto)
      TagFunctions.removeTags({ script: tagEventsKonduto })
      TagFunctions.removeTags({ script: tagKonduto })

      if (tagInitialDataLayer.current) {
        TagFunctions.removeTags(tagInitialDataLayer.current)
      }

      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const body = document.querySelector("body")
    document.removeEventListener("visibilitychange", handleVisibilityChange)
    if (exitPopup) {
      document.addEventListener("visibilitychange", handleVisibilityChange)
      body?.addEventListener("mouseleave", handleMouseleave)
    }
  }, [exitPopup])

  const saveLostSale = async () => {
    if (id.current !== "" && checkoutOrderIdValue === undefined) {
      if (emailValue !== "" || phoneValue !== "") {
        if (!lostSaleSending.current) {
          try {
            lostSaleSending.current = true

            const infos = BrowserFunctions.getInfo()
            const clientIp = await getClientIp()

            // quando o autopreenchimento está ativo no browser e o usuário utiliza
            // está chamando duas vezes o lostsale
            if (!lostSaleSending.current) {
              return
            }

            const lostSale: TLostSale = {
              codeId: lostSaleCodeId.current,
              productPriceId: id.current,
              affiliatedCode: watch("affiliateCode"),
              name: nameValue,
              email: emailValue.trim().toLowerCase(),
              phone: numberOnly(phoneValue),
              cep: numberOnly(zipCodeValue),
              pageAccessId: pageAccessIdValue,
              clientIp,
              browser: infos.browser,
            }

            lostSale.codeId = lostSaleCodeId.current
            const { data: response } = await LostSaleController.insert({
              data: lostSale,
            })
            lostSaleCodeId.current = response
          } finally {
            lostSaleSending.current = false
          }
        } else {
          setTimeout(saveLostSale, 1000)
        }
      }
    }
  }

  const processCheckoutOrderFailed = () => {
    CheckoutFunctions.addAttempts(codeId ?? "")
  }

  const validCheckoutOrderAttempts = () => {
    return CheckoutFunctions.validAttempts(codeId ?? "")
  }

  const Submit = async (dataSale: TSale) => {
    if (!validCheckoutOrderAttempts()) {
      setError(
        "Parece que algumas informações estão incorretas ou incompletas. Para garantir que sua compra seja processada sem problemas, por favor, revise os detalhes e tente novamente."
      )
      return
    }

    setSaleStorage(null)
    dataSale.productPriceId = id.current
    dataSale.lostSaleCode = lostSaleCodeId.current
    dataSale.checkoutModelId = data?.checkoutModelId
    dataSale.kondutoId = localStorage.getItem("visitorID") || ""

    const layout = CheckoutFunctions.getLayoutComponent(
      TCheckoutComponent.ClientsData,
      data?.checkoutComponents
    )
    if (layout !== TCheckoutLayout.None) {
      if (!Validations.email(dataSale.email)) {
        setError("E-mail inválido")
        setFocus("email")
        return
      }

      if (!CheckoutFunctions.isLayout5ModelE(data?.checkoutComponents)) {
        if (!Validations.email(dataSale.emailConfirm)) {
          setError("Confirmação do E-mail inválido")
          setFocus("emailConfirm")
          return
        }

        if (
          dataSale.email.trim().toLowerCase() !==
          dataSale.emailConfirm.trim().toLowerCase()
        ) {
          setError("E-mail não confere com a confirmação digitada.")
          setFocus("email")
          return
        }
      }

      if (!Validations.CPF_CNPJ(dataSale.document)) {
        setError("CPF/CNPJ inválido.")
        setFocus("document")
        return
      }
    }

    const isRecaptcha = CheckoutFunctions.askReCAPTCHA(
      data,
      dataSale,
      accessPageDate.current
    )
    if (isRecaptcha) {
      if (executeRecaptcha) {
        const token = await executeRecaptcha("checkout")
        dataSale.recaptchaToken = token
        handleRecaptchaChange(token)
      }
    } else {
      dataSale.recaptchaToken = null
    }

    setSending(true)
    setSuccess(false)
    setError("")
    try {
      sendPixelsAddPaymentInfo(data, affiliated)

      const response = await OrderController.create({ data: dataSale })
      if (!response.success) {
        setError(Convertions.getErrorPayment(paymentMethodType, response))
        processCheckoutOrderFailed()
      } else {
        const order = response.data as IOrderResponse
        const status = order.status ?? ""
        if (status === "failed") {
          setError(Convertions.getErrorPayment(paymentMethodType, response))
          processCheckoutOrderFailed()
          return
        }

        setDataResult(order)
        setValue("checkoutOrderId", order.checkoutOrderId)

        setViewLoading(true)
        setChargeId(order.chargeId)
        setWaitForApproval(true)
      }
    } finally {
      setSending(false)
    }
  }

  const nextInput = () => {
    if (!disableFocus.current) {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("input")
      )
      const currInput = document.activeElement
      const currInputIndex = inputs.indexOf(currInput)
      const nextinputIndex = (currInputIndex + 1) % inputs.length
      const input = inputs[nextinputIndex]
      if (input) {
        input.focus()
      }
    }
  }

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      if (!fieldSetFocus.current) {
        nextInput()
      }
      fieldSetFocus.current = false
      e.preventDefault()
    }
  }

  const handleAddresNumberKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      fieldSetFocus.current = true
      const neighborhoodValue = watch("address.neighborhood")
      if (neighborhoodValue === "") {
        setFocus("address.neighborhood")
      } else {
        setFocus("address.complement")
      }
      e.preventDefault()
    }
  }

  const handleAddresComplementKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      fieldSetFocus.current = true
      if (
        paymentMethodType === methodTypeCreditCard ||
        paymentMethodType === methodTypeDebitCard
      ) {
        setFocus("payment.cardName")
      }
      e.preventDefault()
    }
  }

  const handleApplyVoucher = () => {
    sendApplyVoucher(voucherIdentifier)
  }

  const handleRemoveVoucher = () => {
    voucherType.current = "voucher"
    setValue("payment.voucherIdentifier", null)
    setValue("payment.voucherValue", null)
    setValue("payment.voucherStatus", "none")
  }

  const handleRecaptchaChange = (token: string | null) => {
    setValue("recaptchaToken", token)
  }

  if (loading) {
    return (
      <Container component="main" maxWidth="md" sx={{ mt: 10, mb: 10 }}>
        <Stack
          alignContent="center"
          alignItems="center"
          sx={{ width: "100%", p: 5 }}
        >
          <CircularProgress />
        </Stack>
      </Container>
    )
  }

  if (errorPrice !== "") {
    return (
      <Container component="main" maxWidth="md" sx={{ mt: 10, mb: 10 }}>
        <Paper square={true} sx={{ width: "100%", borderRadius: 5 }}>
          <Stack alignContent="center" alignItems="center" sx={{ p: 5 }}>
            <Typography
              data-cy="productNotFound"
              data-testid="product-not-found"
            >
              Produto não encontrado
            </Typography>
          </Stack>
        </Paper>
      </Container>
    )
  }

  return (
    <Box
      sx={{
        width: "100%",
        color: checkoutSettings?.color,
        backgroundColor: checkoutSettings?.backgroundColor,
        backgroundImage: `url("${checkoutSettings?.backgroundImage}")`,
        backgroundRepeat: checkoutSettings?.backgroundImageRepeat
          ? "repeat"
          : "no-repeat",
        backgroundPositionX: checkoutSettings?.backgroundImageRepeat
          ? "start"
          : "center",
        backgroundPositionY: checkoutSettings?.backgroundImageRepeat
          ? "start"
          : checkoutSettings?.backgroundImageFixedTop
          ? "start"
          : "center",
        backgroundSize: checkoutSettings?.backgroundImageExpand
          ? "cover"
          : "auto",
        backgroundAttachment: checkoutSettings?.backgroundImageFixedTop
          ? "fixed"
          : "scroll",
        pt: !isNewLayoutCoverImage
          ? 4
          : CheckoutFunctions.isStopWatchFixedTop(list)
          ? 7
          : 0,
        pb: !isNewLayoutCoverImage ? 4 : { xs: 0, sm: 3 },
      }}
    >
      <Container
        component="main"
        maxWidth={false}
        disableGutters={isNewLayoutCoverImage}
        sx={{
          maxWidth:
            list.length > 1 || list[0].children.length > 0 ? "1005px" : "663px",
        }}
      >
        <CheckoutComponenetList
          key="CheckoutComponenetList"
          list={list}
          id={id}
          loading={loading}
          loadingVoucher={loadingVoucher}
          isCheckoutBuilder={list.length > 1 || list[0].children.length > 0}
          searchedZipCode={searchedZipCode}
          data={data}
          control={control}
          errors={errors}
          productInstallments={installment?.main}
          installments={installment?.checkout}
          orderBumps={orderBumps}
          products={products}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
          setValue={setValue}
          onSubmit={handleSubmit(Submit)}
          onKeyDown={handleFormKeyDown}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          orderBumpChange={orderBumpChange}
          setOpenTermsPurchase={setOpenTermsPurchase}
          onSendPixel={sendPixelsInitiateCheckoutData}
          onApplyVoucher={handleApplyVoucher}
          onRemoveVoucher={handleRemoveVoucher}
        />
      </Container>

      <InfoDialog
        open={openZipCodeInvalid}
        onClose={() => setOpenZipCodeInvalid(false)}
        title="CEP não encontrado"
        text="Será necessário preencher as informações do endereço de cobrança manualmente."
        dataCyButton="zipCodeNotFound"
      />

      <TermsPurchaseDialog
        open={openTermsPurchase}
        onClose={() => setOpenTermsPurchase(false)}
      />

      <ExitPopupDialog
        isExitPopup={isExitPopup}
        exitPopup={exitPopup}
        setIsExitPopup={setIsExitPopup}
        applyVoucher={applyVoucher}
      />

      <LoadingBackdrop open={sending} />

      <CheckoutSendingBackdrop
        open={viewLoading}
        paymentMethod={paymentMethodType}
      />

      <ErrorSnackbar open={error !== ""} onClose={() => setError("")}>
        {error}
      </ErrorSnackbar>

      <Snackbar
        id="errorVoucher"
        open={errorVoucher !== ""}
        autoHideDuration={2000}
        onClose={() => setErrorVoucher("")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorVoucher("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorVoucher}
        </Alert>
      </Snackbar>

      <Snackbar
        id="successVoucher"
        open={successVoucher}
        autoHideDuration={2000}
        onClose={() => setSuccessVoucher(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setSuccessVoucher(false)}
          sx={{ width: "100%" }}
        >
          Você recebeu um{" "}
          {voucherType.current === "discount" ? "desconto" : "cupom"} de{" "}
          {watch("payment.voucherType") === TProductVoucherValue.value
            ? NumberFunctions.formatMoneyDefault(watch("payment.voucherValue"))
            : NumberFunctions.toPercentage(watch("payment.voucherValue"))}
        </Alert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Gerado com sucesso
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CheckoutPage
